<template>
  <div class="maindiv">
    <v-row class="align-center headerdiv mx-0 pt-3 pb-1 px-sm-3 no-gutters">
      <v-col cols="auto" class="px-3">
        <a href="https://www.stile.com.pk/" target="_blank">
          <img src="../assets/logo.svg" class="stilelogo" />
        </a>
      </v-col>
      <v-col class="xxxlg--text accent--text fw-700 pl-3"
        >Tile Calculator</v-col
      >
      <v-col cols="auto" class="d-none">
        <div class="progressstatus">
          <div class="count">7/8</div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps"></div>
          <div class="steps d-none"></div>
        </div>
      </v-col>
      <v-col cols="auto" class="px-5">
        <span
          class="material-icons-outlined accentdark--text cursor-pointer"
          @click.stop="Helpdialog = true"
          >question_mark</span
        >
      </v-col>
      <v-col cols="auto" class="d-none d-sm-block">
        <span
          class="
            material-icons-outlined
            xxxxlg--text
            accentdark--text
            cursor-pointer
          "
          @click="fullScreen"
          >fullscreen</span
        >
      </v-col>
    </v-row>

    <v-row class="align-center ma-0 py-0 px-sm-3 measurementdiv">
      <v-col cols="12" class="fw-500 col-sm dgrey--text lg--text pb-0 pb-sm-3"
        >Fill in the details below to get the exact calculation of tile
        needs.</v-col
      >
      <v-col class="xlg--text col-sm-auto py-0 py-sm-3 grey--text fw-700 pr-0"
        >Measuring Units</v-col
      >
      <v-col cols="auto" class="py-0 py-sm-3 text-right pr-0">
        <v-select
          append-icon="$dropdown"
          outlined
          dense
          v-model="RoomSpecs.measuringUnit"
          hide-details
          :items="measuringunits"
          @change="changeMeasuringUnit()"
          item-text="name"
          item-value="value"
          class="wd-180 measuringunits mainmeasuring"
        ></v-select>
      </v-col>
    </v-row>
    <v-form ref="roomSpecForm" v-model="roomSpecFormValid" lazy-validation>
      <v-row no-gutters class="align-center ma-0">
        <v-col cols="px-3">
          <v-row class="secondary px-3 ma-0 align-center py-3 py-sm-6">
            <v-col
              cols="12"
              sm="3"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Room Type
            </v-col>
            <v-col class="px-0">
              <div
                v-for="(item, index) in roomTypes"
                :key="index"
                class="roomtype"
                :class="{
                  selectedroom: item.value == RoomSpecs.roomType,
                  'ml-0': index == 0,
                }"
                @click="RoomSpecs.roomType = item.value"
              >
                <img :src="item.mainImgURL" class="mainimg" />
                <img :src="item.hoverImgURL" class="hoverimg" />
                <p class="mb-0 pt-2 lg--text dgrey--text fw-500 roomname">
                  {{ item.name }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row class="px-3 ma-0 align-center py-3 py-sm-8">
            <v-col
              cols="12"
              sm="3"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Surface Type
            </v-col>
            <v-col
              cols="12"
              class="col-sm surfacetypebtns text-center text-sm-left"
            >
              <v-btn-toggle
                mandatory
                v-model="RoomSpecs.surfaceType"
                @change="surfaceTypeChanged()"
              >
                <v-btn color="white" :value="0" class="text-capitalize white">
                  Both
                </v-btn>
                <v-btn color="white" :value="1" class="text-capitalize white">
                  Wall
                </v-btn>
                <v-btn color="white" :value="2" class="text-capitalize white">
                  Floor
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>

          <v-row
            v-if="RoomSpecs.shape"
            class="px-sm-3 ma-0 secondary Room Dimensions py-sm-3 py-3"
          >
            <v-col
              cols="12"
              sm="3"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Room Dimensions
            </v-col>
            <v-col cols="12" sm="9" class="pt-1 px-sm-0 pt-sm-3 roomdimensions">
              <v-row no-gutters>
                <v-col cols="12" md="4" class="pr-md-1">
                  <v-row class="border-1 border-lsecondary ma-0 rounded">
                    <v-col cols="12" class="dgrey--text lg--text text-center">
                      Room Shape
                    </v-col>
                    <v-col cols="12" class="" v-if="RoomSpecs.shape">
                      <div class="justify-center d-flex d-sm-block pt-0">
                        <v-row
                          class="
                            border-1
                            align-center
                            whitebg
                            border-lsecondary
                            cursor-pointer
                            mx-0
                            rounded
                            wd-100p
                          "
                          @click.stop="Shapedialog = true"
                        >
                          <v-col class="accent--text white xlg--text py-3">{{
                            RoomSpecs.shape.name
                          }}</v-col>
                          <v-col cols="auto" class="ln-height-0 white">
                            <img
                              class="selectedshape"
                              :src="RoomSpecs.shape.dropdownImageURL"
                            />
                          </v-col>
                          <v-col cols="auto" class="white fill-height">
                            <img src="../assets/angle-down.svg"
                          /></v-col>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-center col-sm pt-6 pt-sm-3">
                      <img
                        :src="RoomSpecs.shape.previewImageURL"
                        class="imagepreview"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pl-md-1 pr-0 pt-3 pt-md-0">
                  <v-row
                    class="
                      border-1 border-lsecondary
                      ma-0
                      rounded
                      align-center
                      py-1 py-sm-3
                    "
                  >
                    <v-col cols="12">
                      <v-row class="align-center">
                        <v-col
                          cols="3"
                          lg="2"
                          class="dgrey--text lg--text text-right wd-100 pr-0"
                        >
                          {{
                            RoomSpecs.surfaceType == 1 ? "Wall Length" : "Floor"
                          }}
                        </v-col>
                        <v-col cols="9" sm="9">
                          <v-row class="align-center">
                            <v-col
                              cols="auto"
                              v-for="(item, index) in RoomSpecs.shape.floor
                                .dimensions"
                              :key="index"
                            >
                              <v-text-field
                                hide-details
                                class="wd-140"
                                maxlength="7"
                                type="tel"
                                :label="item.name + RoomSpecs.unitInString"
                                filled
                                @change="RoomSpecs.shape.setWallsLength()"
                                @keydown="allowNumberOnly"
                                :rules="dimensionWithRequiredRule"
                                v-model="item.value"
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      RoomSpecs.surfaceType == 0 || RoomSpecs.surfaceType == 1
                    "
                    class="
                      border-1 border-lsecondary
                      mb-0
                      mx-0
                      rounded
                      align-start
                      py-3
                    "
                  >
                    <v-col cols="12" sm="12">
                      <v-row
                        class="align-center"
                        :class="{
                          wallheightrow: this.RoomSpecs.shape.walls.length > 1,
                        }"
                      >
                        <v-col
                          cols="auto"
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                          :class="{
                            wallheighcol:
                              index + 1 < RoomSpecs.shape.walls.length,
                          }"
                        >
                          <v-row class="align-center">
                            <v-col
                              cols="3"
                              lg="auto"
                              class="
                                dgrey--text
                                lg--text
                                text-right
                                wd-100
                                pr-0
                              "
                            >
                              Wall {{ index + 1 }}
                            </v-col>
                            <v-col cols="auto">
                              <v-text-field
                                hide-details
                                class="wd-140"
                                type="tel"
                                :label="'Height ' + RoomSpecs.unitInString"
                                maxlength="7"
                                filled
                                @keydown="allowNumberOnly"
                                :rules="dimensionWithRequiredRule"
                                v-model="wall.dimensions[0].value"
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-1"
                          v-if="
                            this.RoomSpecs.shape.walls.length <
                            this.RoomSpecs.shape.noOfWalls
                          "
                        >
                          <v-btn
                            outlined
                            small
                            @click="RoomSpecs.addWallsInShapes()"
                            class="addremovebtns"
                          >
                            <v-icon class="xlg--text"> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="pl-1"
                          v-if="this.RoomSpecs.shape.walls.length > 1"
                        >
                          <v-btn
                            outlined
                            small
                            @click="RoomSpecs.removeWallsInShapes()"
                            class="addremovebtns"
                          >
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-sm-3 ma-0 align-center py-6 deductionarea py-3">
            <v-col
              cols="12"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Deduction
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="
                py-1 py-sm-3
                text-center text-sm-left
                dgrey--text
                fw-400
                lg--text
              "
            >
              For door, window or any other area deduction from the tiling
              surface
            </v-col>
            <v-col
              class="surfacetypebtns pt-0 pt-sm-3 text-center text-sm-left"
            >
              <v-btn-toggle
                mandatory
                v-model="RoomSpecs.deduction"
                @change="resetSelectedWall"
              >
                <v-btn
                  color="white"
                  :value="true"
                  class="text-capitalize white"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="white"
                  :value="false"
                  class="text-capitalize white"
                >
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col class="d-none d-sm-block" sm="3"></v-col>

            <v-col v-if="RoomSpecs.deduction" cols="12" class="">
              <v-row
                class="
                  border-1 border-lsecondary
                  ma-0
                  rounded
                  align-center
                  py-1 py-sm-3
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="text-center text-sm-left fw-700 dgrey--text lg--text"
                >
                  Wall
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      class="
                        surfacetypebtns
                        deductionopt
                        pt-0 pt-sm-3
                        text-center text-sm-left
                      "
                    >
                      <v-btn-toggle
                        show-arrows
                        mandatory
                        v-model="selectedWall"
                      >
                        <v-btn
                          color="white"
                          class="text-capitalize white"
                          v-for="(item, index) in RoomSpecs.shape.walls"
                          :key="index"
                          :value="item"
                        >
                          Wall {{ index + 1 }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      class="
                        surfacetypebtns
                        deductionbtns
                        text-center text-sm-left
                      "
                    >
                      <v-btn-toggle
                        multiple
                        v-model="selectedWall.selectedDeductionTypes"
                        @change="deductionTypesChanged()"
                      >
                        <v-btn
                          :value="1"
                          color="white"
                          :class="{ showskirtingtext: showSkirtingText }"
                          class="text-capitalize white mr-3 deductionbtnsitem"
                        >
                          <v-row class="align-center" no-gutters>
                            <v-col
                              cols="auto"
                              class="pr-1 pr-md-2 ln-height-0 deductionicon"
                            >
                              <img
                                src="../assets/ded-door-1.svg"
                                class="mainbtn"
                              />
                              <img
                                src="../assets/ded-door-2.svg"
                                class="hoverbtn"
                              />
                            </v-col>
                            <v-col class="text-left">
                              Door{{
                                showSkirtingText ? " (For Skirting)" : ""
                              }}
                            </v-col>
                          </v-row>
                        </v-btn>
                        <v-btn
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          :value="2"
                          color="white"
                          class="text-capitalize white mr-3 deductionbtnsitem"
                        >
                          <v-row class="align-center" no-gutters>
                            <v-col
                              cols="auto"
                              class="pr-1 pr-md-2 ln-height-0 deductionicon"
                            >
                              <img
                                src="../assets/ded-window-1.svg"
                                class="mainbtn"
                              />
                              <img
                                src="../assets/ded-window-2.svg"
                                class="hoverbtn"
                              />
                            </v-col>
                            <v-col class="text-left"> Window </v-col>
                          </v-row>
                        </v-btn>
                        <v-btn
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          :value="3"
                          color="white"
                          class="text-capitalize white mr-3 deductionbtnsitem"
                        >
                          <v-row class="align-center" no-gutters>
                            <v-col
                              cols="auto"
                              class="pr-1 pr-md-2 ln-height-0 deductionicon"
                            >
                              <img
                                src="../assets/ded-cabinet-1.svg"
                                class="mainbtn"
                              />
                              <img
                                src="../assets/ded-cabinet-2.svg"
                                class="hoverbtn"
                              />
                            </v-col>
                            <v-col class="text-left"> Cabinet </v-col>
                          </v-row>
                        </v-btn>
                        <v-btn
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          :value="4"
                          color="white"
                          class="text-capitalize white mr-3 deductionbtnsitem"
                        >
                          <v-row class="align-center" no-gutters>
                            <v-col
                              cols="auto"
                              class="pr-1 pr-md-2 ln-height-0 deductionicon"
                            >
                              <img
                                src="../assets/ded-closet-1.svg"
                                class="mainbtn"
                              />
                              <img
                                src="../assets/ded-closet-2.svg"
                                class="hoverbtn"
                              />
                            </v-col>
                            <v-col class="text-left"> Closet </v-col>
                          </v-row>
                        </v-btn>
                        <v-btn
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          :value="5"
                          color="white"
                          class="text-capitalize white mr-3 deductionbtnsitem"
                        >
                          <v-row class="align-center" no-gutters>
                            <v-col
                              cols="auto"
                              class="pr-1 pr-md-2 ln-height-0 deductionicon"
                            >
                              <img
                                src="../assets/ded-other-2.svg"
                                class="mainbtn"
                              />
                              <img
                                src="../assets/ded-other-1.svg"
                                class="hoverbtn"
                              />
                            </v-col>
                            <v-col class="text-left"> Other </v-col>
                          </v-row>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-row class="align-center">
                        <v-col
                          cols="12"
                          v-for="(value, propertyName) in groupedWallDeductions"
                          :key="propertyName"
                        >
                          <v-row
                            class="align-center"
                            v-for="(item, itemindex) in value"
                            :key="itemindex"
                            :id="item.typeinString"
                          >
                            <v-col
                              cols="12"
                              sm="auto"
                              class="
                                dgrey--text
                                px-sm-0
                                lg--text
                                text-sm-right
                                wd-100
                                pb-0 pb-sm-3
                              "
                            >
                              {{ item.typeinString }} {{ itemindex + 1 }}
                            </v-col>
                            <v-col cols="auto" class="pr-1 pr-sm-3">
                              <v-text-field
                                hide-details
                                type="tel"
                                class="wd-140"
                                :label="'Width ' + RoomSpecs.unitInString"
                                filled
                                maxlength="7"
                                v-model="item.width"
                                @keydown="allowNumberOnly"
                                :rules="dimensionRule"
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="auto"
                              class="px-1 xlg--text dsecondary--text"
                              v-if="!showSkirtingText"
                            >
                              X
                            </v-col>
                            <v-col
                              class="px-1 pl-sm-3"
                              cols="auto"
                              v-if="!showSkirtingText"
                            >
                              <v-text-field
                                hide-details
                                type="tel"
                                class="wd-140"
                                :label="'Height ' + RoomSpecs.unitInString"
                                v-model="item.height"
                                maxlength="7"
                                @keydown="allowNumberOnly"
                                :rules="dimensionRule"
                                filled
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              v-if="
                                itemindex + 1 == value.length &&
                                value.length < maxdeductions &&
                                itemindex + 1 < maxdeductions
                              "
                              cols="auto"
                              class="pl-1"
                            >
                              <v-btn
                                @click="
                                  selectedWall.addWallDeduction(item.type)
                                "
                                outlined
                                small
                                class="addremovebtns"
                              >
                                <v-icon class="xlg--text"> mdi-plus </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="auto"
                              class="px-1"
                              v-if="value.length > 1 && itemindex > 0"
                            >
                              <v-btn
                                @click="selectedWall.removeWallDeduction(item)"
                                outlined
                                small
                                class="addremovebtns"
                              >
                                <v-icon> mdi-minus </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="
                RoomSpecs.deduction &&
                (RoomSpecs.surfaceType == 0 || RoomSpecs.surfaceType == 2)
              "
              cols="12"
              class="pt-1 pt-sm-3"
            >
              <v-row
                class="
                  border-1 border-lsecondary
                  ma-0
                  rounded
                  align-center
                  py-1 py-sm-3
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="text-center text-sm-left fw-700 dgrey--text lg--text"
                >
                  Floor
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row class="align-center">
                    <v-col cols="12">
                      <v-row
                        class="align-center"
                        v-for="(item, index) in RoomSpecs.shape.floor
                          .deductions"
                        :key="index"
                      >
                        <v-col
                          cols="12"
                          sm="auto"
                          class="
                            dgrey--text
                            px-sm-0
                            lg--text
                            text-sm-right
                            wd-100
                            pb-0 pb-sm-3
                          "
                        >
                          Deduction {{ index + 1 }}
                        </v-col>
                        <v-col cols="auto" class="pr-1 pr-sm-3">
                          <v-text-field
                            hide-details
                            type="tel"
                            class="wd-140"
                            v-model="item.length"
                            :label="'Length ' + RoomSpecs.unitInString"
                            maxlength="7"
                            filled
                            @keydown="allowNumberOnly"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-1 xlg--text dsecondary--text"
                        >
                          X
                        </v-col>
                        <v-col cols="auto" class="px-1 pl-sm-3">
                          <v-text-field
                            hide-details
                            type="tel"
                            class="wd-140"
                            v-model="item.width"
                            :label="'Width ' + RoomSpecs.unitInString"
                            maxlength="7"
                            filled
                            @keydown="allowNumberOnly"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-1"
                          v-if="
                            index + 1 ==
                              RoomSpecs.shape.floor.deductions.length &&
                            RoomSpecs.shape.floor.deductions.length <
                              maxdeductions &&
                            index + 1 < maxdeductions
                          "
                        >
                          <v-btn
                            outlined
                            small
                            @click="RoomSpecs.shape.floor.addFloorDeduction()"
                            class="addremovebtns"
                          >
                            <v-icon class="xlg--text"> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="pl-1"
                          v-if="
                            RoomSpecs.shape.floor.deductions.length > 1 &&
                            index > 0
                          "
                        >
                          <v-btn
                            outlined
                            small
                            @click="
                              RoomSpecs.shape.floor.removeFloorDeduction(item)
                            "
                            class="addremovebtns"
                          >
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3 secondary ma-0 align-center py-4 py-sm-8">
            <v-col
              cols="12"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Wastage
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="
                py-1 py-sm-3
                text-center text-sm-left
                dgrey--text
                fw-400
                lg--text
              "
            >
              5% extra to cover accidental damage during transit or at home
            </v-col>
            <v-col class="surfacetypebtns text-center text-sm-left">
              <v-btn-toggle mandatory v-model="RoomSpecs.addWastage">
                <v-btn
                  color="white"
                  :value="true"
                  class="text-capitalize white"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="white"
                  :value="false"
                  class="text-capitalize white"
                >
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="px-3 ma-0 align-center py-3 py-sm-5">
            <v-col
              cols="12"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Tile Size
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="
                text-center text-sm-left
                py-1 py-sm-3
                dgrey--text
                fw-400
                lg--text
                pr-5
              "
              v-if="RoomSpecs.surfaceType != 2"
            >
              Do you want same tile size for each surface?
            </v-col>
            <v-col
              cols="12"
              sm="9"
              v-if="RoomSpecs.surfaceType != 2"
              class="surfacetypebtns text-center text-sm-left"
            >
              <v-btn-toggle mandatory v-model="RoomSpecs.sameTileSize">
                <v-btn
                  color="white"
                  :value="true"
                  class="text-capitalize white"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="white"
                  :value="false"
                  class="text-capitalize white"
                >
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12">
              <v-row
                v-if="RoomSpecs.sameTileSize"
                class="
                  border-1 border-lsecondary
                  ma-0
                  rounded
                  align-center
                  pt-1
                  pb-4
                  py-sm-6
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="dgrey--text lg--text text-center fw-700 text-sm-left"
                >
                  Surface Tile Size
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row class="align-center">
                    <v-col cols="12">
                      <v-row
                        class="align-center justify-center justify-sm-start"
                      >
                        <v-col cols="auto white pa-0 rounded">
                          <v-select
                            v-model="RoomSpecs.tileSize"
                            append-icon="$dropdown"
                            hide-details
                            :items="tileSizeList"
                            item-text="displayName"
                            return-object
                            class="wd-220"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !RoomSpecs.sameTileSize &&
                  (RoomSpecs.surfaceType == 0 || RoomSpecs.surfaceType == 2)
                "
                class="
                  border-1 border-lsecondary
                  ma-0
                  rounded
                  align-center
                  pt-1
                  pb-4
                  py-sm-6
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="dgrey--text lg--text text-center fw-700 text-sm-left"
                >
                  Floor Tile Size
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row class="align-center">
                    <v-col cols="12">
                      <v-row class="align-center tilesize">
                        <v-col
                          cols="auto"
                          class="
                            dgrey--text
                            lg--text
                            text-center text-sm-right
                            wd-100
                          "
                        >
                          Floor
                        </v-col>
                        <v-col cols="auto white pa-0 rounded">
                          <v-select
                            append-icon="$dropdown"
                            hide-details
                            v-model="RoomSpecs.shape.floor.tileSize"
                            :items="tileSizeList"
                            item-text="displayName"
                            return-object
                            class="wd-220"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !RoomSpecs.sameTileSize &&
                  (RoomSpecs.surfaceType == 0 || RoomSpecs.surfaceType == 1)
                "
                class="
                  border-1 border-lsecondary
                  mb-0
                  mx-0
                  rounded
                  align-start
                  py-3
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="
                    dgrey--text
                    subheadings
                    lg--text
                    fw-700
                    text-center text-sm-left
                  "
                >
                  Wall Tile Size
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row class="align-center mt-0 mb-0 wallsizings">
                    <v-col
                      cols="auto"
                      class="my-1"
                      v-for="(item, index) in RoomSpecs.shape.walls"
                      :key="index"
                    >
                      <v-row
                        class="
                          align-center
                          justify-sm-start
                          tilesize
                          justify-center
                        "
                      >
                        <v-col
                          cols="auto"
                          class="
                            dgrey--text
                            lg--text
                            text-center text-sm-right
                            wd-100
                          "
                        >
                          Wall {{ index + 1 }}
                        </v-col>
                        <v-col cols="auto white pa-0 rounded">
                          <v-select
                            append-icon="$dropdown"
                            hide-details
                            class="wd-220"
                            v-model="item.tileSize"
                            :items="tileSizeList"
                            item-text="displayName"
                            return-object
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            class="px-3 secondary ma-0 align-center py-4 py-sm-8"
            v-if="RoomSpecs.surfaceType == 2"
          >
            <v-col
              cols="12"
              class="text-center text-sm-left grey--text fw-700 xlg--text"
            >
              Skirting
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="
                py-1 py-sm-3
                text-center text-sm-left
                dgrey--text
                fw-400
                lg--text
              "
            >
              Border running along the bottom of the wall when the wall is not
              tiled
            </v-col>
            <v-col
              class="surfacetypebtns pt-0 pt-sm-3 text-center text-sm-left"
            >
              <v-btn-toggle v-model="RoomSpecs.skirting" mandatory>
                <v-btn color="white" :value="0" class="text-capitalize white">
                  None
                </v-btn>
                <v-btn
                  :disabled="RoomSpecs.surfaceType != 2"
                  color="white"
                  :value="1"
                  class="text-capitalize white"
                >
                  4"
                </v-btn>
                <v-btn
                  :disabled="RoomSpecs.surfaceType != 2"
                  color="white"
                  :value="2"
                  class="text-capitalize white"
                >
                  6"
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="px-3 ma-0 accent align-center py-1">
            <v-col cols="12" class="text-center">
              <v-btn
                outlined
                class="mx-2 wd-160"
                depressed
                x-large
                color="white"
                @click="showResetConfirmDialog = true"
              >
                Reset
              </v-btn>
              <v-btn
                filled
                class="wd-160 mx-2 accent--text"
                depressed
                x-large
                @click.stop="calculateResult"
                color="white"
              >
                Calculate
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="Shapedialog" max-width="320" :fullscreen="isMobile">
      <v-card>
        <v-card-text class="pa-0 shapeselections">
          <v-row
            v-for="(item, index) in shapes"
            :key="index"
            class="
              align-center
              px-3
              ma-0
              borderBottom-1
              cursor-pointer
              border-lsecondary
            "
            @click="setShapeAsSelected(item)"
          >
            <v-col class="dgrey--text xlg--text py-6">{{ item.name }}</v-col>
            <v-col cols="4" class="ln-height-0 text-center">
              <img :src="item.dialogImageURL"
            /></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="Helpdialog"
      scrollable
      max-width="900"
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" class="text-center">
              <p class="accentdark--text fw-500 xxlg--text">Help and Advice</p>
              <p class="accentdark--text md--text fw-500 mb-0 wordbreak">
                Welcome to Stile Tile Calculator. Follow the below steps to
                calculate your tile requirements:
              </p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-8 py-0">
          <v-row class="ma-0">
            <v-col cols="12" class="text-left pt-0">
              <ul class="accentdark--text md--text">
                <li>Select the Room Type for your space.</li>
                <li>
                  Select the Surface Type you want to tile i.e., wall, floor, or
                  both.
                </li>
                <li>
                  Select the likely shape of the room/space you need to tile and
                  enter the exact corresponding dimensions for the selected
                  surface type.
                </li>
                <li>
                  Select if any deductions will be made. If yes, select the type
                  of deduction(s) for each surface and enter the required
                  dimensions.
                </li>
                <li>
                  You have the option to add 5% Wastage as a safety margin to
                  allow for room designing, accidental breakages, cutting of
                  tiles or so.
                </li>
                <li>
                  Select if you want to install the same sized tile on each
                  surface.
                </li>
                <li>
                  Select the size of tile you want to install on each surface or
                  select <span class="fw-700">‘Not Sure’</span> if you want to
                  explore tile sizes further.
                </li>
                <li>
                  Click <span class="fw-700"> ‘Calculate’ </span> get the area
                  and quantity of tiles required.
                </li>
                <li>
                  You can view surface-wise details of your results in
                  <span class="fw-700">‘View Details’</span>.
                </li>
                <li>You can also export your results in PDF format.</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="my-0">
          <v-row class="ma-0">
            <v-col class="text-center">
              <v-btn
                filled
                class="mx-2 wd-140 white--text text-capitalize"
                depressed
                x-large
                color="accentdark"
                @click.stop="Helpdialog = false"
              >
                Get Started
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :fullscreen="isMobile"
      v-model="Resultdialog"
      scrollable
      content-class="Resultdialog"
      max-width="1200"
    >
      <v-card v-if="RoomSpecs.shape">
        <v-card-title class="pa-0">
          <v-row
            class="
              align-center
              headerdiv
              mx-0
              pt-3
              pb-3 pb-sm-3
              px-0 px-sm-3
              no-gutters
            "
          >
            <v-col cols="auto" class="pl-3 ln-height-0 mb-3 mb-sm-0">
              <img src="../assets/logo.svg" class="wd-60 d-none d-sm-block" />
              <span
                class="
                  material-icons-outlined
                  xxxxlg--text
                  dgrey--text
                  cursor-pointer
                  d-sm-none
                "
                @click.stop="Resultdialog = false"
                >arrow_back</span
              >
            </v-col>
            <v-col
              cols="10"
              class="xxlg--text col-sm accent--text fw-700 pl-3 mb-3 mb-sm-0"
              >Tile Calculator</v-col
            >
            <v-col sm="auto">
              <v-select
                append-icon="$dropdown"
                outlined
                dense
                v-model="RoomSpecs.measuringUnit"
                hide-details
                :items="measuringunits"
                @change="changeMeasuringUnit()"
                item-text="name"
                item-value="value"
                class="wd-180 measuringunits ml-3"
              ></v-select>
            </v-col>

            <v-col cols="auto" class="px-3">
              <span
                class="
                  material-icons-outlined
                  xxxxlg--text
                  dgrey--text
                  d-none d-sm-block
                  cursor-pointer
                "
                @click.stop="Resultdialog = false"
                >close</span
              >
              <v-btn
                filled
                class="white--text d-sm-none"
                depressed
                color="accent"
                @click="generatePDF()"
              >
                Export PDF
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <!-- PDF Content Here -->
        <v-card-text
          id="container"
          class="pa-3 md--text"
          :key="resultsKey"
          v-if="RoomSpecs.shape"
        >
          <v-row class="borderBottom-1 border-lsecondary">
            <v-col
              cols="12"
              class="text-center accent--text py-2 py-md-4 xxlg--text fw-700"
            >
              RESULTS
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="borderRight-1 border-lsecondary pt-0 pt-md-3 datacol1"
            >
              <v-row class="d-none d-md-flex">
                <v-col cols="12"> &nbsp;</v-col>
              </v-row>
              <v-row class="lgrey mt-0 mt-md-3">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Room Type</v-col>
                    <v-col class="text-right dgrey--text fw-500">{{
                      RoomSpecs.roomTypeinString
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12">
                  <v-row no-gutters class="align-center">
                    <v-col>Room Shape</v-col>
                    <v-col cols="auto" class="ln-height-0 pr-3">
                      <img
                        class="selectedshape"
                        :src="RoomSpecs.shape.dropdownImageURL"
                      />
                    </v-col>
                    <v-col cols="auto" class="text-right dgrey--text fw-500">{{
                      RoomSpecs.shape.name
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Measuring Units</v-col>
                    <v-col class="text-right dgrey--text fw-500">{{
                      RoomSpecs.measuringUnitTypeinString
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Surface Type</v-col>
                    <v-col class="text-right dgrey--text fw-500">{{
                      RoomSpecs.surfaceTypeinString
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Floor Input</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      ><span
                        v-for="(floorSize, index) in RoomSpecs.shape.floor
                          .dimensions"
                        :key="index"
                      >
                        {{ floorSize.value }}
                        <span
                          v-if="
                            floorSize.value &&
                            index <
                              RoomSpecs.shape.floor.dimensions.length - 1 &&
                            RoomSpecs.shape.floor.dimensions.length > 1
                          "
                        >
                          |
                        </span> </span
                      ><span>
                        {{ RoomSpecs.unitInString }}
                      </span></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Wall Input</v-col>

                    <v-col
                      class="text-right dgrey--text fw-500"
                      v-if="RoomSpecs.surfaceType == 2"
                    >
                      N/A
                    </v-col>
                    <v-col class="text-right dgrey--text fw-500" v-else>
                      <span
                        v-for="(wall, index) in RoomSpecs.shape.walls"
                        :key="index"
                      >
                        {{ wall.dimension }}
                        <span
                          v-if="
                            index < RoomSpecs.shape.walls.length - 1 &&
                            RoomSpecs.shape.walls.length > 1
                          "
                        >
                          |
                        </span> </span
                      ><span> {{ RoomSpecs.unitInString }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Tile Size</v-col>
                    <v-col
                      class="
                        text-right
                        dgrey--text
                        fw-500
                        min-wd-0
                        text-truncate
                        pl-3
                      "
                    >
                      <v-tooltip bottom right :disabled="!showtooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            ref="tileSizeDiv"
                            class="d-block text-truncate tooltipdiv"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="d-block text-truncate"
                              v-if="RoomSpecs.sameTileSize"
                            >
                              {{
                                RoomSpecs.tileSize
                                  ? RoomSpecs.tileSize.displayName
                                  : ""
                              }}
                            </span>
                            <span
                              ref="tileSizeSpan"
                              class="block text-truncate"
                              v-else
                            >
                              <span v-if="RoomSpecs.surfaceType != 1">
                                {{
                                  RoomSpecs.shape.floor.tileSize
                                    ? RoomSpecs.shape.floor.tileSize
                                        ?.displayName
                                    : ""
                                }}
                                |</span
                              >
                              <span
                                v-for="(wall, index) in RoomSpecs.shape.walls"
                                :key="index"
                              >
                                {{
                                  wall.tileSize ? wall.tileSize.displayName : ""
                                }}
                                <span
                                  v-if="
                                    wall.tileSize &&
                                    wall.tileSize.displayName &&
                                    index < RoomSpecs.shape.walls.length - 1 &&
                                    RoomSpecs.shape.walls.length > 1
                                  "
                                >
                                  |
                                </span>
                              </span>
                            </span>
                          </div>
                        </template>
                        <span
                          ><span v-if="RoomSpecs.sameTileSize">
                            {{
                              RoomSpecs.tileSize
                                ? RoomSpecs.tileSize.displayName
                                : ""
                            }}
                          </span>
                          <span v-else>
                            <span v-if="RoomSpecs.surfaceType != 1">
                              {{
                                RoomSpecs.shape.floor.tileSize
                                  ? RoomSpecs.shape.floor.tileSize?.displayName
                                  : ""
                              }}
                              |</span
                            >
                            <span
                              v-for="(wall, index) in RoomSpecs.shape.walls"
                              :key="index"
                            >
                              {{
                                wall.tileSize ? wall.tileSize.displayName : ""
                              }}
                              <span
                                v-if="
                                  wall.tileSize &&
                                  wall.tileSize.displayName &&
                                  index < RoomSpecs.shape.walls.length - 1 &&
                                  RoomSpecs.shape.walls.length > 1
                                "
                              >
                                |
                              </span>
                            </span>
                          </span></span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-none d-md-flex">
                <v-col cols="12"> &nbsp; </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="datacol2 mt-6 mt-sm-3 mt-md-0">
              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto"></v-col>
                    <v-col class="text-right grey--text fw-500"
                      >Total ({{ RoomSpecs.surfaceTypeinString }})</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12" class="fw-700 lg--text">
                  <v-row no-gutters>
                    <v-col cols="auto">Total Area</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalArea }} {{ RoomSpecs.unitInString
                      }}<sup>2</sup></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto"
                      >Wastage ({{ RoomSpecs.addWastage ? "5" : "0" }}%)</v-col
                    >
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalWastage }}
                      {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Deduction</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalDeduction }}
                      {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto"
                      >Skirting ({{ RoomSpecs.skirtingInString }})</v-col
                    >
                    <v-col
                      class="text-right dgrey--text fw-500"
                      v-if="RoomSpecs.surfaceType == 2"
                      >{{ RoomSpecs.getFloorSkirtingArea }}
                      {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                    ><v-col class="text-right dgrey--text fw-500" v-else>
                      N/A
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col>
                  <v-row no-gutters>
                    <v-col cols="auto"
                      >Tile Area (with wastage, deduction
                      {{
                        RoomSpecs.surfaceType == 2 ? "and skirting" : ""
                      }})</v-col
                    >
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalTileArea }}
                      {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" class="fw-700 lg--text">
                  <v-row no-gutters>
                    <v-col cols="auto">Box Quantity</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalTileBoxes }} Box(es)</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="lgrey">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Bond Quantity</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalBond }} Bag(s)</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">Grout Quantity</v-col>
                    <v-col class="text-right dgrey--text fw-500"
                      >{{ RoomSpecs.getTotalGrout }} Bottle(s)</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-expansion-panels v-model="panels" class="viewdetail mt-sm-3 mt-10">
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="">
                <v-row
                  class="
                    viewdetailheader
                    align-center
                    text-center
                    justify-center
                    accent--text
                    py-3
                    xlg--text
                    fw-700
                    cursor-pointer
                  "
                >
                  <v-col cols="auto" class="py-0"> VIEW DETAILS </v-col>
                  <v-col cols="auto" class="pl-1 py-0">
                    <span
                      class="material-icons-outlined xxxxlg--text accent--text"
                      >expand_more</span
                    ></v-col
                  >
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    class="
                      pb-6
                      px-0
                      borderBottom-1
                      border-lsecondary
                      overflow-x-auto
                    "
                  >
                    <table class="tiledetailtbl">
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            Floor
                          </th>
                          <th
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            Wall ({{ wall.dimension }}
                            {{ RoomSpecs.unitInStringWithoutBrackets }})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="fw-700 lg--text">
                          <td>Total Area</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.shape.getArea() }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getArea }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Wastage ({{ RoomSpecs.addWastage ? "5" : "0" }}%)
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorWastage }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getWastage }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                        </tr>
                        <tr>
                          <td>Deduction</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorDeductions }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getDeductions }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                        </tr>
                        <tr>
                          <td>Skirting ({{ RoomSpecs.skirtingInString }})</td>
                          <td v-if="RoomSpecs.surfaceType == 2">
                            {{ RoomSpecs.getFloorSkirtingArea }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                          <td v-else-if="RoomSpecs.surfaceType == 0">N/A</td>

                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            N/A
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Tile Area
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="d-inline-block infoicon"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span class="material-icons-outlined">
                                    info
                                  </span>
                                </div>
                              </template>
                              <span
                                >Tile Area (with wastage, deduction
                                {{
                                  RoomSpecs.surfaceType == 2
                                    ? "and skirting"
                                    : ""
                                }})</span
                              >
                            </v-tooltip>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorTileArea }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getTileArea }}
                            {{ RoomSpecs.unitInString }}<sup>2</sup>
                          </td>
                        </tr>
                        <tr>
                          <td>Tile Size</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{
                              RoomSpecs.sameTileSize
                                ? RoomSpecs.tileSize.displayName
                                : RoomSpecs.shape.floor.tileSize.displayName
                            }}
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{
                              RoomSpecs.sameTileSize
                                ? RoomSpecs.tileSize.displayName
                                : wall.tileSize.displayName
                            }}
                          </td>
                        </tr>
                        <tr class="fw-700 lg--text">
                          <td>Box Quantity</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorTotalBoxes }}
                            Box(es)
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="d-inline-block infoicon"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span class="material-icons-outlined">
                                    info
                                  </span>
                                </div>
                              </template>
                              <span>
                                SQM/Box:
                                {{
                                  RoomSpecs.sameTileSize
                                    ? RoomSpecs.tileSize.sqmperBox
                                    : RoomSpecs.shape.floor.tileSize.sqmperBox
                                }}
                                <br />
                                Total SQM:
                                {{ RoomSpecs.getFloorTotalSQM }}</span
                              >
                            </v-tooltip>
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getTotalBoxes }}
                            Box(es)
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="d-inline-block infoicon"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span class="material-icons-outlined">
                                    info
                                  </span>
                                </div>
                              </template>
                              <span>
                                SQM/Box:
                                {{
                                  RoomSpecs.sameTileSize
                                    ? RoomSpecs.tileSize.sqmperBox
                                    : wall.tileSize.sqmperBox
                                }}
                                <br />
                                Total SQM:
                                {{ wall.getTotalSQM }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>Bond Quantity</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorBond }}
                            Bag(s)
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getBond }}
                            Bag(s)
                          </td>
                        </tr>
                        <tr>
                          <td>Grout Quantity</td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 2
                            "
                          >
                            {{ RoomSpecs.getFloorGrout }}
                            Bottle(s)
                          </td>
                          <td
                            v-if="
                              RoomSpecs.surfaceType == 0 ||
                              RoomSpecs.surfaceType == 1
                            "
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.getGrout }}
                            Bottle(s)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions class="d-none d-sm-block">
          <v-row class="ma-0 align-center pa-0">
            <v-col cols="12" class="text-center px-0">
              <v-btn
                outlined
                class="mx-2 wd-140"
                depressed
                large
                color="grey"
                @click.stop="Resultdialog = false"
              >
                Close
              </v-btn>
              <v-btn
                outlined
                class="wd-140 mx-2 accent--text"
                depressed
                large
                color="accent"
                @click="generatePDF()"
              >
                Export PDF
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showResetConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row class="mt-0 pt-3">
            <v-col class="fw-500 sm--text accentdark--text">
              Are you sure you want to reset?
            </v-col>
          </v-row>
          <v-row class="justify-center mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentxlight--text mr-2"
                small
                @click="showResetConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn outlined class="accent--text" small @click="reset()"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="result"
      :pdf-quality="2"
      pdf-format="a4"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="PDFresult">
        <!-- PDF Content Here -->
        <v-app content-class=" " style="background-color: #ffffff">
          <v-card style="background-color: #ffffff">
            <v-card-text
              class="pa-3 md--text"
              v-if="RoomSpecs.shape"
              style="background-color: #ffffff"
            >
              <v-row class="borderBottom-1 border-lsecondary">
                <v-col
                  cols="12"
                  class="
                    text-center
                    accent--text
                    py-2 py-md-4
                    xxlg--text
                    fw-700
                  "
                >
                  RESULTS
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  md="6"
                  class="borderRight-1 border-lsecondary datacol1"
                >
                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto"></v-col>
                        <v-col class="text-right white--text fw-500">a</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Room Type</v-col>
                        <v-col class="text-right dgrey--text fw-500">{{
                          RoomSpecs.roomTypeinString
                        }}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12">
                      <v-row class="align-center">
                        <v-col cols="12">
                          <v-row no-gutters class="align-center">
                            <v-col>Room Shape</v-col>
                            <v-col cols="auto" class="ln-height-0 pr-3">
                              <img
                                style="width: 15px"
                                class="selectedshape"
                                :src="RoomSpecs.shape.dropdownImageURL"
                              />
                            </v-col>
                            <v-col
                              cols="auto"
                              class="text-right dgrey--text fw-500"
                              >{{ RoomSpecs.shape.name }}</v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Measuring Units</v-col>
                        <v-col class="text-right dgrey--text fw-500">{{
                          RoomSpecs.measuringUnitTypeinString
                        }}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Surface Type</v-col>
                        <v-col class="text-right dgrey--text fw-500">{{
                          RoomSpecs.surfaceTypeinString
                        }}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Floor Input</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          ><span
                            v-for="(floorSize, index) in RoomSpecs.shape.floor
                              .dimensions"
                            :key="index"
                          >
                            {{ floorSize.value }}
                            <span
                              v-if="
                                floorSize.value &&
                                index <
                                  RoomSpecs.shape.floor.dimensions.length - 1 &&
                                RoomSpecs.shape.floor.dimensions.length > 1
                              "
                            >
                              |
                            </span> </span
                          ><span>
                            {{ RoomSpecs.unitInString }}
                          </span></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Wall Input</v-col>
                        <v-col
                          class="text-right dgrey--text fw-500"
                          v-if="RoomSpecs.surfaceType == 2"
                        >
                          N/A
                        </v-col>
                        <v-col class="text-right dgrey--text fw-500" v-else>
                          <span
                            v-for="(wall, index) in RoomSpecs.shape.walls"
                            :key="index"
                          >
                            {{ wall.dimension }}
                            <span
                              v-if="
                                index < RoomSpecs.shape.walls.length - 1 &&
                                RoomSpecs.shape.walls.length > 1
                              "
                            >
                              |
                            </span>
                          </span>
                          <span>
                            {{ RoomSpecs.unitInString }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Tile Size</v-col>
                        <v-col class="text-right dgrey--text fw-500">
                          <span v-if="RoomSpecs.sameTileSize">
                            {{
                              RoomSpecs.tileSize
                                ? RoomSpecs.tileSize.displayName
                                : ""
                            }}
                          </span>
                          <span v-else>
                            <span v-if="RoomSpecs.surfaceType != 1">
                              {{
                                RoomSpecs.shape.floor.tileSize
                                  ? RoomSpecs.shape.floor.tileSize?.displayName
                                  : ""
                              }}
                              |</span
                            >
                            <span
                              v-for="(wall, index) in RoomSpecs.shape.walls"
                              :key="index"
                            >
                              {{
                                wall.tileSize ? wall.tileSize.displayName : ""
                              }}
                              <span
                                v-if="
                                  wall.tileSize &&
                                  wall.tileSize.displayName &&
                                  index < RoomSpecs.shape.walls.length - 1 &&
                                  RoomSpecs.shape.walls.length > 1
                                "
                              >
                                |
                              </span>
                            </span>
                          </span></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12"> &nbsp; </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="6" class="datacol2">
                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto"></v-col>
                        <v-col class="text-right grey--text fw-500"
                          >Total ({{ RoomSpecs.surfaceTypeinString }})</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12" class="fw-700 lg--text">
                      <v-row no-gutters>
                        <v-col cols="auto">Total Area</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto"
                          >Wastage ({{
                            RoomSpecs.addWastage ? "5" : "0"
                          }}%)</v-col
                        >
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalWastage }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Deduction</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalDeduction }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto"
                          >Skirting ({{ RoomSpecs.skirtingInString }})</v-col
                        >
                        <v-col
                          class="text-right dgrey--text fw-500"
                          v-if="RoomSpecs.surfaceType == 2"
                          >{{ RoomSpecs.getFloorSkirtingArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                        ><v-col class="text-right dgrey--text fw-500" v-else>
                          N/A
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col>
                      <v-row no-gutters>
                        <v-col cols="auto"
                          >Tile Area (with wastage, deduction
                          {{
                            RoomSpecs.surfaceType == 2 ? "and skirting" : ""
                          }})</v-col
                        >
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalTileArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="fw-700 lg--text">
                      <v-row no-gutters>
                        <v-col cols="auto">Box Quantity</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalTileBoxes }} Box(es)</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="lgrey">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Bond Quantity</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalBond }} Bag(s)</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="auto">Grout Quantity</v-col>
                        <v-col class="text-right dgrey--text fw-500"
                          >{{ RoomSpecs.getTotalGrout }} Bottle(s)</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="
                    pb-6
                    px-0
                    borderBottom-1
                    border-lsecondary
                    overflow-x-auto
                  "
                >
                  <table class="tiledetailtbl">
                    <thead>
                      <tr>
                        <th></th>
                        <th
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          Floor
                        </th>
                        <th
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          Wall ({{ wall.dimension }}
                          {{ RoomSpecs.unitInStringWithoutBrackets }})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="fw-700 lg--text">
                        <td>Total Area</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.shape.getArea() }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Wastage ({{ RoomSpecs.addWastage ? "5" : "0" }}%)
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorWastage }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getWastage }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Deduction</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorDeductions }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getDeductions }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Skirting ({{ RoomSpecs.skirtingInString }})</td>
                        <td v-if="RoomSpecs.surfaceType == 2">
                          {{ RoomSpecs.getFloorSkirtingArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                        <td v-else-if="RoomSpecs.surfaceType == 0">N/A</td>

                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          N/A
                        </td>
                      </tr>
                      <tr>
                        <td>Tile Area</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorTileArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getTileArea }}
                          {{ RoomSpecs.unitInString }}<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Tile Size</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{
                            RoomSpecs.sameTileSize
                              ? RoomSpecs.tileSize.displayName
                              : RoomSpecs.shape.floor.tileSize.displayName
                          }}
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{
                            RoomSpecs.sameTileSize
                              ? RoomSpecs.tileSize.displayName
                              : wall.tileSize.displayName
                          }}
                        </td>
                      </tr>
                      <tr class="fw-700 lg--text">
                        <td>Box Quantity</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorTotalBoxes }}
                          Box(es)
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getTotalBoxes }}
                          Box(es)
                        </td>
                      </tr>
                      <tr>
                        <td>Bond Quantity</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorBond }}
                          Bag(s)
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getBond }}
                          Bag(s)
                        </td>
                      </tr>
                      <tr>
                        <td>Grout Quantity</td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 2
                          "
                        >
                          {{ RoomSpecs.getFloorGrout }}
                          Bottle(s)
                        </td>
                        <td
                          v-if="
                            RoomSpecs.surfaceType == 0 ||
                            RoomSpecs.surfaceType == 1
                          "
                          v-for="(wall, index) in RoomSpecs.shape.walls"
                          :key="index"
                        >
                          {{ wall.getGrout }}
                          Bottle(s)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-app>
      </section>
    </vue-html2pdf>
    <v-dialog v-model="Welcomedialog" max-width="900" :fullscreen="isMobile">
      <v-card>
        <v-card-text class="pa-8">
          <v-row class="ma-0">
            <v-col cols="12" class="px-3 d-flex justify-center">
              <img src="../assets/logo.svg" class="stilelogo" />
              <span
                class="xxxlg--text accentdark--text fw-700 pl-3"
                style="align-self: center"
                >Tile Calculator</span
              >
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12" class="text-center">
              <p class="accentdark--text md--text">
                Welcome to Stile Tile Calculator, the perfect tool for
                calculating the boxes of tiles you need for your project and the
                size of the area you need to tile.
              </p>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12" class="text-center">
              <p class="accentdark--text md--text">
                We understand it's important to get your tiling project right,
                and that starts with calculating the area you want to tile.
                Whether you need to calculate the tile area for your wall or
                floor, or already know what you want and just need to know how
                much you need, you can be assured that our tools below will be
                able to help.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <v-btn
                filled
                class="mx-2 wd-140 white--text text-capitalize"
                depressed
                x-large
                color="accentdark"
                @click.stop="Welcomedialog = false"
              >
                Get Started
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script >
import apimethods from "../mixins/apimethods";
import { Square } from "../models/shapes/Square";
import { Rectangle } from "../models/shapes/Rectangle";
import { Triangle } from "../models/shapes/Triangle";
import { Circle } from "../models/shapes/Circle";
import { SemiCircle } from "../models/shapes/SemiCircle";
import { Trapezoid } from "../models/shapes/Trapezoid";
import { Parallelogram } from "../models/shapes/Parallelogram";
import { Rhombus } from "../models/shapes/Rhombus";
import { Pentagon } from "../models/shapes/Pentagon";
import { RoomSpec } from "../models/RoomSpec";
import { MeasuringUnitType } from "@/enums/MeasuringUnitType";
import { RoomType } from "@/enums/RoomType";
import { Group } from "@/utilities/utilities";
import { MeasuringUnit } from "@/static/MeasuringUnit";
import { SurfaceType } from "@/enums/SurfaceType";
import VueHtml2pdf from "vue-html2pdf";
import { DeductionType } from "@/enums/DeductionType";
export default {
  name: "Home",
  components: { VueHtml2pdf },
  mixins: [apimethods],
  data() {
    return {
      panels: [],
      roomSpecFormValid: false,
      maxdeductions: process.env.VUE_APP_MAX_DEDUCTIONS,
      shapes: [],
      dialog: false,
      Shapedialog: false,
      Helpdialog: false,
      Welcomedialog: false,
      isMobile: false,
      showtooltip: false,
      bondGroutList: [],
      selectedWall: { deductions: [] },
      tileSizeList: [],
      Resultdialog: false,
      showResetConfirmDialog: false,
      showSkirtingText: false,
      resultsKey: 0,
      RoomSpecs: new RoomSpec(),
      lastDeductionTypesSelected: [],
      dimensionRule: [
        ((v) => !!v?.toString().trim() || this.regextest().test(v)) ||
          // /^(?!0+$)[0-9][0-9]{0,2}(?:\.[0-9]{0,2})?$/.test(v)) ||
          "Value should be in the format ###.##",
      ],
      dimensionWithRequiredRule: [
        (v) => !!v?.toString().trim() || "Value is required",
        // ((v) => /^(?!0+$)[0-9][0-9]{0,2}(?:\.[0-9]{0,2})?$/.test(v)) ||
        ((v) => this.regextest().test(v)) ||
          "Value should be in the format ###.##",
      ],
      measuringunits: [
        {
          name: "Feet & Inches",
          value: MeasuringUnitType.FeetAndInches,
        },
        {
          name: "Meter & CM",
          value: MeasuringUnitType.Meter,
        },
      ],
      roomTypes: [
        {
          name: "Bedroom",
          mainImgURL: require(`../assets/bedroom-1.svg`),
          hoverImgURL: require(`../assets/bedroom-2.svg`),
          value: RoomType.Bedroom,
        },
        {
          name: "Living Room",
          mainImgURL: require(`../assets/Livingroom-1.svg`),
          hoverImgURL: require(`../assets/Livingroom-2.svg`),
          value: RoomType.Livingroom,
        },
        {
          name: "Kitchen",
          mainImgURL: require(`../assets/Kitchen-1.svg`),
          hoverImgURL: require(`../assets/Kitchen-2.svg`),
          value: RoomType.Kitchen,
        },
        {
          name: "Bathroom",
          mainImgURL: require(`../assets/bathroom-1.svg`),
          hoverImgURL: require(`../assets/bathroom-2.svg`),
          value: RoomType.Bathroom,
        },
        {
          name: "Other",
          mainImgURL: require(`../assets/Other-1.svg`),
          hoverImgURL: require(`../assets/Other-2.svg`),
          value: RoomType.Other,
        },
      ],
    };
  },
  watch: {
    panels: function (value) {
      if (value != undefined) {
        setTimeout(() => {
          var container = document.querySelector("#container");
          container.scrollTop = container.scrollHeight;
        }, 200);
      }
    },
  },
  computed: {
    groupedWallDeductions() {
      if (
        !this.$data.selectedWall.groupedWallDeductions ||
        !this.$data.selectedWall.selectedDeductionTypes
      )
        return [];
      var deductions = Object.assign(this.$data.selectedWall.deductions, []);
      deductions = deductions.filter((x) => {
        if (this.$data.selectedWall.selectedDeductionTypes.includes(x.type))
          return x;
      });
      return Group(deductions, "type");
    },
  },
  beforeMount() {
    this.initializeData();
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    });
  },
  created() {
    this.$data.isMobile = window.innerWidth < 599;
    this.renderToolTip();
    window.addEventListener("resize", this.checkMobile);
  },
  mounted() {
    this.showWelcomePopup();
  },
  destroyed() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    showWelcomePopup() {
      var popupShown = localStorage.getItem("WelcomePopupShown");
      if (!popupShown) {
        this.Welcomedialog = true;
        localStorage.WelcomePopupShown = true;
      }
    },
    regextest() {
      if (MeasuringUnit.UNIT == MeasuringUnitType.FeetAndInches)
        return /^(?!0+$)[0-9][0-9]{0,3}(?:\.[0-9]{0,2})?$/;
      else if (MeasuringUnit.UNIT == MeasuringUnitType.Meter)
        return /^(?!0+$)[0-9][0-9]{0,2}(?:\.[0-9]{0,2})?$/;
    },
    checkMobile() {
      this.$data.isMobile = window.innerWidth < 599;
      this.renderToolTip();
    },
    renderToolTip() {
      setTimeout(() => {
        if (this.$refs.tileSizeDiv && this.$refs.tileSizeSpan) {
          this.$data.showtooltip =
            this.$refs.tileSizeDiv.offsetWidth <
            this.$refs.tileSizeSpan.offsetWidth;
        } else {
          this.$data.showtooltip = false;
        }
      }, 100);
    },
    fullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    },
    deductionTypesChanged() {
      var type = "";
      this.$data.selectedWall.selectedDeductionTypes.forEach((element) => {
        if (!this.$data.lastDeductionTypesSelected.includes(element)) {
          type = DeductionType[element];
        }
      });

      this.$data.lastDeductionTypesSelected =
        this.$data.selectedWall.selectedDeductionTypes;
      this.scrollToDiv(type);
    },
    scrollToDiv(divId) {
      if (!divId) return;
      setTimeout(() => {
        var divToScrollTo = document.querySelector("#" + divId);
        setTimeout(() => {
          window.scrollTo({
            top: divToScrollTo.offsetTop - 200,
            left: 0,
            behavior: "smooth",
          });
        }, 10);
      }, 100);
    },
    resetSelectedWall() {
      this.$data.selectedWall = this.RoomSpecs.shape.walls[0];
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    allowNumberOnly(e) {
      var key = e.key;
      var lstAllowedKeys = [
        "Backspace",
        "Tab",
        ".",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "End",
        "Home",
      ];
      if (lstAllowedKeys.includes(key)) return;
      if (!/^\d+/g.test(key)) e.preventDefault();
    },
    reset() {
      this.$data.showResetConfirmDialog = false;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.initializeData();
    },
    surfaceTypeChanged() {
      if (this.$data.RoomSpecs.surfaceType == SurfaceType.Floor) {
        this.$data.showSkirtingText = true;
      } else {
        this.$data.showSkirtingText = false;
      }
      this.$data.RoomSpecs.updateSkirtingType();
    },
    changeMeasuringUnit() {
      this.$data.shapes.forEach((element) => {
        element.floor.dimensions.forEach((floorDimension) => {
          floorDimension.value = this.convert(floorDimension.value);
        });
        element.floor.deductions.forEach((floorDeduction) => {
          floorDeduction.length = this.convert(floorDeduction.length);
          floorDeduction.width = this.convert(floorDeduction.width);
          floorDeduction.height = this.convert(floorDeduction.height);
        });
        element.walls.forEach((wall) => {
          wall.dimensions.forEach((wallDimension) => {
            wallDimension.value = this.convert(wallDimension.value);
          });
          wall.deductions.forEach((wallDeduction) => {
            wallDeduction.length = this.convert(wallDeduction.length);
            wallDeduction.width = this.convert(wallDeduction.width);
            wallDeduction.height = this.convert(wallDeduction.height);
          });
        });
      });
      MeasuringUnit.UNIT = this.$data.RoomSpecs.measuringUnit;
    },
    convert(value) {
      if (value == null || value == undefined || value == "") return null;
      else if (this.$data.RoomSpecs.measuringUnit == MeasuringUnitType.Meter) {
        return Number((value / 3.28).toFixed(2));
      } else if (
        this.$data.RoomSpecs.measuringUnit == MeasuringUnitType.FeetAndInches
      ) {
        return Number((value * 3.28).toFixed(2));
      }
    },
    calculateResult() {
      this.renderToolTip();
      if (!this.$refs.roomSpecForm.validate()) {
        setTimeout(() => {
          var errorId = "0";
          for (const property in this.$refs.roomSpecForm.errorBag) {
            if (this.$refs.roomSpecForm.errorBag[property] == true) {
              errorId = property.toString();
              break;
            }
          }
          var input = this.$refs.roomSpecForm.inputs.find(
            (x) => x._uid == errorId
          );
          setTimeout(() => {
            window.scrollTo({
              top: input.$el.offsetTop - 200,
              left: 0,
              behavior: "smooth",
            });
          }, 500);
        }, 10);
        return;
      }
      this.$data.Resultdialog = true;
      this.$data.resultsKey++;
    },
    initializeData() {
      this.$store.commit("showLoadingModal");
      this.$data.RoomSpecs = new RoomSpec();
      var self = this;
      var getBondGrout = this.GetRequest(
        "api/TileCalculator/GetBondGrout",
        undefined
      )
        .then((res) => {
          if (res != undefined && res.data != undefined) {
            self.$data.bondGroutList = res.data;
            self.$data.RoomSpecs.bond = self.$data.bondGroutList[0];
            self.$data.RoomSpecs.grout = self.$data.bondGroutList[1];
          }
        })
        .catch((ex) => {});
      var getTileSize = this.GetRequest(
        "api/TileCalculator/GetTileSize",
        undefined
      )
        .then((res) => {
          if (res != undefined && res.data != undefined) {
            self.$data.tileSizeList = [
              {
                id: 0,
                length: 0.0,
                width: 0.0,
                displayName: "Not Sure",
                sqmperBox: 0.0,
                tilesPerBox: 0,
              },
              ...res.data,
            ];
          }
        })
        .catch((ex) => {});

      let initShapes = new Promise(function (resolve, reject) {
        try {
          var arr = [];
          arr.push(new Rectangle());
          arr.push(new Square());
          arr.push(new Triangle());
          arr.push(new Circle());
          arr.push(new SemiCircle());
          arr.push(new Trapezoid());
          arr.push(new Parallelogram());
          arr.push(new Rhombus());
          arr.push(new Pentagon());

          resolve(arr);
        } catch (error) {
          reject(error);
        }
      });

      initShapes.then(function (value) {
        self.$data.shapes = value;
        self.setShapeAsSelected(self.$data.shapes[0]);
      });

      Promise.all([getBondGrout, getTileSize, initShapes]).then((values) => {
        this.$data.RoomSpecs.tileSize = this.$data.RoomSpecs.tileSize;
        this.$data.RoomSpecs.addWastage = this.$data.RoomSpecs.addWastage;
        this.$data.RoomSpecs.sameTileSize = this.$data.RoomSpecs.sameTileSize;
        this.$store.commit("hideLoadingModal");
      });
    },
    setShapeAsSelected(item) {
      this.$refs.roomSpecForm.resetValidation();
      var prevSelectedShape = this.$data.shapes.find((x) => x.selected == true);
      if (prevSelectedShape) prevSelectedShape.selected = false;
      item.selected = true;
      this.$data.Shapedialog = false;
      this.$data.RoomSpecs.shape = item;
      this.$data.selectedWall = { deductions: [] };
    },
  },
};
</script>

