import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Wall } from "../Wall";
import { Floor } from "../Floor";
import { SurfaceType } from "@/enums/SurfaceType";

export class Circle extends Shape {
    floor: Floor = new Floor(["r (Radius) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }
    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-circle.svg`);
    dialogImageURL: string = require(`../../assets/Circle-1.svg`);
    dropdownImageURL: string = require(`../../assets/Circle-2.svg`);
    name: string = "Circle";
    shapeType: ShapeType = ShapeType.Circle;
    noOfWalls: number = 1;
    getArea(): number {
        return Number((Math.PI * this.floor.dimensions[0].value * this.floor.dimensions[0].value).toFixed(2));
    }

    getRunningFeet(): number {
        return Number((2 * Math.PI * this.floor.dimensions[0].value).toFixed(2));
    }
    setWallsLength() {
        this.walls[0].dimensions[1].value = this.getRunningFeet();
    }
}