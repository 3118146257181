import { MeasuringUnitType } from "@/enums/MeasuringUnitType";
import { RoomType } from "@/enums/RoomType";
import { SkirtingType } from "@/enums/SkirtingType";
import { SurfaceType } from "@/enums/SurfaceType";
import { Shape } from "@/abstract/Shape";
import { TileSize } from "./TileSize";
import { BondGrout } from "./BondGrout";
import { ConvertSQFTToSQM, ConvertSQMSkirting } from "@/utilities/calculationhelper";
import { Deduction } from "./Deduction";
import { DeductionType } from "@/enums/DeductionType";
import { Config } from "@/static/Config";
import { Wall } from "./Wall";

export class RoomSpec {
    measuringUnit: MeasuringUnitType = MeasuringUnitType.FeetAndInches
    roomType: RoomType = RoomType.Bedroom
    surfaceType: SurfaceType = SurfaceType.Both
    shape !: Shape
    _deduction: boolean = false
    _addWastage: boolean = true
    _sameTileSize: boolean = true
    _tileSize: TileSize = {
        id: 0,
        length: 0.0,
        width: 0.0,
        displayName: "Not Sure",
        sqmperBox: 0.0,
        tilesPerBox: 0,
    }
    skirting: SkirtingType = SkirtingType.None
    _bond!: BondGrout
    _grout!: BondGrout
    get deduction() {
        return this._deduction;
    }

    set deduction(value: boolean) {
        this._deduction = value;
        Config.DEDUCTION = value;
    }
    get addWastage() {
        return this._addWastage;
    }

    set addWastage(value: boolean) {
        this._addWastage = value;
        Config.ADDWASTAGE = value;
    }

    get sameTileSize() {
        return this._sameTileSize;
    }

    set sameTileSize(value: boolean) {
        this._sameTileSize = value;
        Config.SAMETILESIZE = value;
    }

    get bond() {
        return this._bond;
    }

    set bond(value: BondGrout) {
        this._bond = value;

        Config.BOND = value;

    }

    get grout() {
        return this._grout;
    }

    set grout(value: BondGrout) {
        this._grout = value;
        Config.GROUT = value;

    }

    get tileSize() {
        return this._tileSize;
    }

    set tileSize(value: TileSize) {
        this._tileSize = value;
        Config.SAMETILESIZEVALUE = value;

    }
    get unitInString(): string {
        if (this.measuringUnit == MeasuringUnitType.FeetAndInches)
            return "(ft)";
        return "(m)";
    }

    get unitInStringWithoutBrackets(): string {
        if (this.measuringUnit == MeasuringUnitType.FeetAndInches)
            return "ft";
        return "m";
    }

    updateSkirtingType() {
        if (this.surfaceType != SurfaceType.Floor)
            this.skirting = SkirtingType.None;
    }

    get roomTypeinString(): string {
        switch (this.roomType) {
            case RoomType.Livingroom:
                return "Living Room";
            default:
                return RoomType[this.roomType];
        }
    }
    get surfaceTypeinString(): string {
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return "Both: Floor & Wall";
            default:
                return SurfaceType[this.surfaceType];
        }
    }
    get measuringUnitTypeinString(): string {
        switch (this.measuringUnit) {
            case MeasuringUnitType.FeetAndInches:
                return "Feet & Inches";
            case MeasuringUnitType.Meter:
                return "Meter";
        }
    }

    get skirtingInString(): string {
        switch (this.skirting) {
            case SkirtingType.None:
                return "None";
            case SkirtingType.FourInch:
                return "4'";
            case SkirtingType.SixInch:
                return "6'";
        }
    }

    //#region --FLOOR METHODS--
    get getFloorWastage() {
        if (!this.addWastage)
            return 0;
        return Number((this.shape.getArea() * 0.05).toFixed(2));
    }

    get getFloorAreawithWastage(): number {
        return this.shape.getArea() + this.getFloorWastage;
    }

    wallsDoorDeductionSum(): number {
        if (!this.deduction)
            return 0;
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += Number(element.doorDeductionsSum);
        });
        return Number(sum);
    }
    addWallsInShapes() {
        if (this.shape.walls.length < this.shape.noOfWalls)
            this.shape.walls.push(new Wall())
        this.shape.setWallsLength();
    }
    removeWallsInShapes() {
        this.shape.walls.pop();
    }

    get getFloorSkirtingArea(): number {
        if (this.skirting == SkirtingType.None)
            return 0;
        var skirting = Number(((this.shape.getRunningFeet() - this.wallsDoorDeductionSum()) * 0.33).toFixed(2));

        if (this.skirting == SkirtingType.SixInch)
            skirting = Number(((this.shape.getRunningFeet() - this.wallsDoorDeductionSum()) * 0.5).toFixed(2));

        return ConvertSQMSkirting(skirting);
    }

    get totalFloorAreaWithSkirting(): number {
        return this.getFloorAreawithWastage + this.getFloorSkirtingArea;
    }

    get getFloorTotalBoxes(): number {
        var tileSize = this.sameTileSize ? this.tileSize : this.shape.floor.tileSize;
        if (tileSize.sqmperBox == 0)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getFloorTileArea) / tileSize.sqmperBox);
    }

    get getFloorBond(): number {
        if (!this.bond)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getFloorTileArea - Number(this.getFloorWastage)) / this.bond.sqmperBag);
    }

    get getFloorGrout(): number {
        if (!this.grout)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getFloorTileArea - Number(this.getFloorWastage)) / this.grout.sqmperBag);
    }

    get getFloorDeductions(): number {
        if (!this.deduction)
            return 0;
        let sum = 0;
        this.shape.floor.deductions.forEach(element => {
            sum += element.length * element.width;
        });
        if (isNaN(sum))
            sum = 0;
        return Number(sum.toFixed(2));
    }

    get getFloorTileArea(): number {
        if (this.getFloorDeductions > this.totalFloorAreaWithSkirting)
            return 0;
        var tileArea = this.totalFloorAreaWithSkirting - this.getFloorDeductions;
        return Number(tileArea.toFixed(2));
    }

    get getFloorTotalSQM(): number {
        var sqm = this.sameTileSize
            ? this.tileSize?.sqmperBox
            : this.shape.floor.tileSize?.sqmperBox

        return Number((sqm * this.getFloorTotalBoxes).toFixed(2));
    }
    //#endregion

    //#region --COMMULATIVE RESULT METHODS--

    get getTotalWallArea(): number {
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += element.getArea;
        });
        return sum;
    }

    get getTotalArea(): number {
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return Number((this.shape.getArea() + this.getTotalWallArea).toFixed(2));
            case SurfaceType.Wall:
                return Number(this.getTotalWallArea.toFixed(2));
            case SurfaceType.Floor:
                return this.shape.getArea();
        }
    }

    get getTotalWallsWastage() {
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += element.getWastage;
        });
        return sum;
    }

    get getTotalWastage() {
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return Number((this.getFloorWastage + this.getTotalWallsWastage).toFixed(2));
            case SurfaceType.Wall:
                return Number((this.getTotalWallsWastage).toFixed(2));
            case SurfaceType.Floor:
                return this.getFloorWastage;
        }
    }

    get getTotalWallsDeductions() {
        if (!this.deduction)
            return 0;
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += element.getDeductions;
        });
        return Number(sum.toFixed(2));
    }

    get getTotalDeduction() {
        if (!this.deduction)
            return 0;
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return Number((this.getFloorDeductions + this.getTotalWallsDeductions).toFixed(2));
            case SurfaceType.Wall:
                return Number((this.getTotalWallsDeductions).toFixed(2));
            case SurfaceType.Floor:
                return Number((this.getFloorDeductions).toFixed(2));
        }
    }

    get getTotalWallsTileArea() {
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += element.getTileArea;
        });
        return sum;
    }

    get getTotalTileArea() {
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return Number((this.getFloorTileArea + this.getTotalWallsTileArea).toFixed(2));
            case SurfaceType.Wall:
                return Number((this.getTotalWallsTileArea).toFixed(2));
            case SurfaceType.Floor:
                return Number((this.getFloorTileArea).toFixed(2));
        }
    }

    get getTotalWallsTileBoxes() {
        let sum = 0;
        this.shape.walls.forEach(element => {
            sum += element.getTotalBoxes;
        });
        return sum;
    }

    get getTotalTileBoxes() {
        switch (this.surfaceType) {
            case SurfaceType.Both:
                return this.getFloorTotalBoxes + this.getTotalWallsTileBoxes;
            case SurfaceType.Wall:
                return this.getTotalWallsTileBoxes;
            case SurfaceType.Floor:
                return this.getFloorTotalBoxes;
        }
    }


    get getTotalBond() {
        if (!this.bond)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getTotalTileArea - Number(this.getTotalWastage)) / this.bond.sqmperBag);
    }
    get getTotalGrout() {
        if (!this.grout)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getTotalTileArea - Number(this.getTotalWastage)) / this.grout.sqmperBag);
    }
    //#endregion

}
