import { Shape } from "@/abstract/Shape";
import { DeductionType } from "@/enums/DeductionType";
import { SkirtingType } from "@/enums/SkirtingType";
import { ConvertSQFTToSQM } from "@/utilities/calculationhelper";
import { BondGrout } from "./BondGrout";
import { Deduction } from "./Deduction";
import { Field } from "./Field";
import { TileSize } from "./TileSize";

export class Floor {
    constructor(dimNames: Array<string>) {
        //this.shape = parentShape;
        dimNames.forEach(element => {
            this.dimensions.push(new Field(element, null));
        });
        this.deductions.push(new Deduction(DeductionType.Other));
    }

    dimensions: Array<Field> = new Array<Field>();
    deductions: Array<Deduction> = new Array<Deduction>();
    tileSize: TileSize = {
        id: 0,
        length: 0.0,
        width: 0.0,
        displayName: "Not Sure",
        sqmperBox: 0.0,
        tilesPerBox: 0,
    }

    addFloorDeduction() {
        this.deductions.push(new Deduction(DeductionType.Other));
    }
    removeFloorDeduction(item: Deduction) {
        this.deductions = this.deductions.filter((x: Deduction) => x != item);
    }
}