
import Vue from "vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    showLoading() {
      return this.$store.state.showLoading;
    },
  },
});
