import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Floor } from "../Floor";
import { Wall } from "../Wall";
import { SurfaceType } from "@/enums/SurfaceType";

export class Pentagon extends Shape {
    floor: Floor = new Floor(["a (Length) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }
    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-pentagon.svg`);
    dialogImageURL: string = require(`../../assets/Pentagon-1.svg`);
    dropdownImageURL: string = require(`../../assets/Pentagon-2.svg`);
    name: string = "Pentagon";
    shapeType: ShapeType = ShapeType.Pentagon;
    noOfWalls: number = 5;
    getArea(): number {
        var constant = 6.881909602355868;
        return Number(((Math.pow(this.floor.dimensions[0].value, 2) * constant) / 4).toFixed(2));
    }

    getRunningFeet(): number {
        return this.floor.dimensions[0].value * 5;
    }
    setWallsLength() {
        this.walls.forEach((wall: Wall) => {
            wall.dimensions[1].value = this.floor.dimensions[0].value;
        })
    }
}