import { MeasuringUnitType } from "@/enums/MeasuringUnitType";
import { MeasuringUnit } from "@/static/MeasuringUnit";

function ConvertSQFTToSQM(sqft: number): number {
    if (MeasuringUnit.UNIT == MeasuringUnitType.FeetAndInches)
        return Number((sqft / 10.76).toFixed(2));

    return Number(sqft.toFixed(2));
}
function ConvertSQMSkirting(sqm: number): number {
    if (MeasuringUnit.UNIT == MeasuringUnitType.Meter)
        return Number((sqm / 3.28).toFixed(2));

    return Number(sqm.toFixed(2));
}

export { ConvertSQFTToSQM }
export { ConvertSQMSkirting }