import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Floor } from "../Floor";
import { Wall } from "../Wall";
import { SurfaceType } from "@/enums/SurfaceType";

export class Parallelogram extends Shape {
    floor: Floor = new Floor(["a (Length 1) ", "b (Length 2) ", "h (Height) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }
    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-parrallelogram.svg`);
    dialogImageURL: string = require(`../../assets/Parallelogram-1.svg`);
    dropdownImageURL: string = require(`../../assets/Parallelogram-2.svg`);
    name: string = "Parallelogram";
    shapeType: ShapeType = ShapeType.Parallelogram;
    noOfWalls: number = 4;
    getArea(): number {
        return Number((this.floor.dimensions[1].value * this.floor.dimensions[2].value).toFixed(2));
    }

    getRunningFeet(): number {
        return this.floor.dimensions[0].value * 2 + this.floor.dimensions[1].value * 2;
    }
    setWallsLength() {
        this.walls[0].dimensions[1].value = this.floor.dimensions[0].value;
        this.walls[1].dimensions[1].value = this.floor.dimensions[1].value;
        this.walls[2].dimensions[1].value = this.floor.dimensions[0].value;
        this.walls[3].dimensions[1].value = this.floor.dimensions[1].value;
    }
}