import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Floor } from "../Floor";
import { Wall } from "../Wall";
import { SurfaceType } from "@/enums/SurfaceType";

export class SemiCircle extends Shape {
    floor: Floor = new Floor(["r (Radius) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }
    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-semicircle.svg`);
    dialogImageURL: string = require(`../../assets/Semi-circle-1.svg`);
    dropdownImageURL: string = require(`../../assets/Semi-circle-2.svg`);
    name: string = "Semi Circle";
    shapeType: ShapeType = ShapeType.SemiCircle;
    noOfWalls: number = 2;
    getArea(): number {
        return Number(((Math.PI * this.floor.dimensions[0].value * this.floor.dimensions[0].value) / 2).toFixed(2));
    }

    getRunningFeet(): number {
        return Number((((2 * Math.PI * this.floor.dimensions[0].value) / 2) + (this.floor.dimensions[0].value * 2)).toFixed(2));
    }
    setWallsLength() {
        this.walls[0].dimensions[1].value = this.floor.dimensions[0].value * 2;
        this.walls[1].dimensions[1].value = Number((2 * Math.PI * this.floor.dimensions[0].value / 2).toFixed(2));

    }
}