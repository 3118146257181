import { Shape } from "@/abstract/Shape";
import { DeductionType } from "@/enums/DeductionType";
import { Config } from "@/static/Config";
import { ConvertSQFTToSQM } from "@/utilities/calculationhelper";
import { Guid } from "@/utilities/Guid";
import { Group } from "@/utilities/utilities";
import { BondGrout } from "./BondGrout";
import { Deduction } from "./Deduction";
import { Field } from "./Field";
import { TileSize } from "./TileSize";

export class Wall {
    constructor() {
        this.dimensions.push(new Field("Height ", null));
        this.dimensions.push(new Field("Length ", null));
        this.deductions.push(new Deduction(DeductionType.Door));
        this.deductions.push(new Deduction(DeductionType.Window));
        this.deductions.push(new Deduction(DeductionType.Cabinet));
        this.deductions.push(new Deduction(DeductionType.Closet));
        this.deductions.push(new Deduction(DeductionType.Other));
    }
    dimensions: Array<Field> = new Array<Field>();
    deductions: Array<Deduction> = new Array<Deduction>();
    selectedDeductionTypes!: Array<DeductionType>;
    tileSize: TileSize = {
        id: 0,
        length: 0.0,
        width: 0.0,
        displayName: "Not Sure",
        sqmperBox: 0.0,
        tilesPerBox: 0,
    }
    Id: string = Guid.newGuid();
    get dimension(): string {
        return String(this.dimensions[0].value)
    }
    addWallDeduction(type: DeductionType) {
        this.deductions.push(new Deduction(type));
    }
    removeWallDeduction(item: Deduction) {
        this.deductions = this.deductions.filter((x: Deduction) => x != item);
    }

    get groupedWallDeductions(): Array<any> {
        return Group(this.deductions, "type");
    }

    get doorDeductionsSum(): number {
        var doorDeductions = this.deductions.filter((x: Deduction) => x.type == DeductionType.Door)
        let sum = 0;
        doorDeductions.forEach(element => {
            if (element.width)
                sum += Number(element.width);
        });
        return Number(sum);
    }

    get getArea(): number {
        return Number((this.dimensions[1].value * this.dimensions[0].value).toFixed(2));
    }
    get getWastage(): number {
        if (!Config.ADDWASTAGE)
            return 0;
        return Number((this.getArea * 0.05).toFixed(2));
    }

    get getAreaWithWastage(): number {
        return this.getArea + this.getWastage;
    }

    get getDeductions(): number {
        if (!Config.DEDUCTION)
            return 0;
        let sum = 0;
        this.deductions.forEach(element => {
            if (element.width && element.height)
                sum += (element.width * element.height);
        });
        if (isNaN(sum))
            sum = 0;
        return Number(sum.toFixed(2));
    }

    get getTileArea(): number {
        var area = this.getAreaWithWastage;
        var Deduction = this.getDeductions;

        if (area > Deduction)
            return Number((area - Deduction).toFixed(2));

        return 0;
    }

    get getTotalSQM(): number {
        var sqm = Config.SAMETILESIZE
            ? Config.SAMETILESIZEVALUE?.sqmperBox
            : this.tileSize?.sqmperBox

        return Number((this.getTotalBoxes * sqm).toFixed(2));
    }

    get getTotalBoxes(): number {
        var tSize = Config.SAMETILESIZE ? Config.SAMETILESIZEVALUE : this.tileSize;
        if (!tSize || tSize.sqmperBox == 0)
            return 0;

        return Math.ceil(ConvertSQFTToSQM(this.getTileArea) / tSize.sqmperBox);
    }

    get getBond(): number {
        if (!Config.BOND)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getTileArea) / Config.BOND.sqmperBag);
    }

    get getGrout(): number {
        if (!Config.GROUT)
            return 0;
        return Math.ceil(ConvertSQFTToSQM(this.getTileArea) / Config.GROUT.sqmperBag);
    }
}

