export enum ShapeType {
    Square = 1,
    Rectangle,
    Triangle,
    Circle,
    SemiCircle,
    Sector,
    Ellipse,
    Trapezoid,
    Parallelogram,
    Rhombus,
    Kite,
    Pentagon,
    Hexagon,
    Octagon,
    AnnulusRing,
}