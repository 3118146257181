import { DeductionType } from "@/enums/DeductionType";

export class Deduction {
    type !: DeductionType
    length !: number
    width !: number
    height !: number
    constructor(dType: DeductionType) {
        this.type = dType;
    }
    get typeinString(): string {
        return DeductionType[this.type];
    }
}