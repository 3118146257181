import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import dropdown from "@/components/icons/dropdown.vue";
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true
        },
        themes: {
          light: {
            white: "#ffffff",
            accent: "#018fcb",
            accentxlight:"#909db0",
            accentlight: "#ccecfc",
            accentdark: "#223c61",
            dgrey:"#555555",
            grey:"#b2b2b2",
            lgrey:"#f2f2f2",
            secondary:"#f5f5f5",
            lsecondary:"#dbdce1",
            xlsecondary:"#eeeeee",
            dsecondary:"#878787"
          }
        }
      },
      icons: {
      values: {
        dropdown: {
          component: dropdown
        },
      }
    }
});
