import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Floor } from "../Floor";
import { Wall } from "../Wall";
import { SurfaceType } from "@/enums/SurfaceType";

export class Trapezoid extends Shape {
    floor: Floor = new Floor(["a (Length 1) ", "b (Length 2) ", "h (Height) ", "c (Length 3) ", "d (Length 4) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }

    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-trapezoid.svg`);
    dialogImageURL: string = require(`../../assets/Trapezoid-1.svg`);
    dropdownImageURL: string = require(`../../assets/Trapezoid-2.svg`);
    name: string = "Trapezoid";
    shapeType: ShapeType = ShapeType.Trapezoid;
    noOfWalls: number = 4;
    getArea(): number {
        var a = Number(this.floor.dimensions[0].value);
        var b = Number(this.floor.dimensions[1].value);
        return Number((((a + b) * this.floor.dimensions[2].value) / 2).toFixed(2));
    }

    getRunningFeet(): number {
        return Number(this.floor.dimensions[0].value) + Number(this.floor.dimensions[1].value) + Number(this.floor.dimensions[3].value) + Number(this.floor.dimensions[4].value)
    }
    setWallsLength() {
        this.walls[0].dimensions[1].value = this.floor.dimensions[0].value;
        this.walls[1].dimensions[1].value = this.floor.dimensions[1].value;
        this.walls[2].dimensions[1].value = this.floor.dimensions[3].value;
        this.walls[3].dimensions[1].value = this.floor.dimensions[4].value;
    }
}