import { BondGrout } from "@/models/BondGrout";
import { TileSize } from "@/models/TileSize";

export class Config {
    static ADDWASTAGE: boolean = true;
    static DEDUCTION: boolean = true;
    static SAMETILESIZEVALUE: TileSize
    static BOND: BondGrout
    static GROUT: BondGrout
    static SAMETILESIZE: boolean = true
}