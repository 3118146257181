import { ShapeType } from "@/enums/ShapeType";
import { Shape } from "@/abstract/Shape";
import { Floor } from "../Floor";
import { Wall } from "../Wall";
import { SurfaceType } from "@/enums/SurfaceType";

export class Rhombus extends Shape {
    floor: Floor = new Floor(["a (Length) ", "h (Height) "]);
    walls: Wall[] = new Array<Wall>();
    constructor() {
        super()
        this.walls.push(new Wall());
    }
    selected: boolean = false;
    previewImageURL: string = require(`../../assets/preview-rhombus.svg`);
    dialogImageURL: string = require(`../../assets/Rhombus-1.svg`);
    dropdownImageURL: string = require(`../../assets/Rhombus-2.svg`);
    name: string = "Rhombus";
    shapeType: ShapeType = ShapeType.Rhombus;
    noOfWalls: number = 4;
    getArea(): number {
        return Number((this.floor.dimensions[0].value * this.floor.dimensions[1].value).toFixed(2));
    }

    getRunningFeet(): number {
        return this.floor.dimensions[0].value * 4;
    }
    setWallsLength() {
        this.walls.forEach((wall: Wall) => {
            wall.dimensions[1].value = this.floor.dimensions[0].value;
        })
    }
}