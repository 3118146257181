import { ShapeType } from "@/enums/ShapeType";
import { SkirtingType } from "@/enums/SkirtingType";
import { SurfaceType } from "@/enums/SurfaceType";
import { Floor } from "@/models/Floor";
import { RoomSpec } from "@/models/RoomSpec";
import { Wall } from "@/models/Wall";
export abstract class Shape {
    abstract previewImageURL: string
    abstract dialogImageURL: string
    abstract dropdownImageURL: string
    abstract selected: boolean
    abstract name: string
    abstract shapeType: ShapeType
    abstract floor: Floor
    abstract walls: Array<Wall>
    abstract noOfWalls: number
    abstract getArea(): number
    abstract getRunningFeet(): number
    abstract setWallsLength(): any;
}