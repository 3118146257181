import axios from 'axios';

export default {
    data() {
    },
    methods: {
        GetRequest(actionName, parameters) {
            let querystring = "";
            let i = 0;
            if (parameters != undefined && parameters.length > 0) {
                querystring += "?";

                parameters.forEach((element) => {
                    if (i != 0) querystring += "&";
                    querystring += element.Key + "=" + element.Value;
                    i++;
                });
            }

            var axiosInstance = axios.create({
                "Content-Type": "application/json", baseURL: process.env.VUE_APP_API_LINK,
                headers: {
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            });

            return axiosInstance
                .get(process.env.VUE_APP_API_LINK + actionName + querystring, null).then(res => { return res; }).catch(ex => {
                    var exJson = ex.toJSON();
                    if (exJson.status == 401 && exJson.config._retry == true) {
                    }
                    else if (exJson.status == 401) {
                    }
                    else
                        Promise.reject(ex);
                });
        },
    }
}
